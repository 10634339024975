/* BOOTSTRAP OVERRIDE */


.container {
    @media (min-width: 1230px) {
        width: 1200px;
        max-width: 100%;
    }
}

.bg-white, .header, .hero-form, .navigation, .navigation-toggle, .product, .product-list-item__logo-box-link, .share-product-menu, .tt-menu {
    /* height: 100%; */
}
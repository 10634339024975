/* FORM SEARCH */

.form-search {
    .form-control {
        height: 40px;
        box-shadow: inset 0 0 2px rgba($black, .2);
        padding-right: 50px;
    }
}

.form-search__submit {
    @extend .border-0,
    .shadow-none,
    .h-100,
    .position-absolute,
    .top-0,
    .end-0,
    .bg-transparent,
    .px-3;
}
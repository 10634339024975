/* TYPEHEAD FORM */

.typeahead-form {
    @extend .position-relative;

    .form-control {
        @extend .pe-5;
    }
}

.tt-menu {
    @extend .w-100,
    .overflow-hidden;
    background: transparent !important;
}

.tt-dataset {
    @extend .bg-white,
    .border,
    .rounded-3,
    .border-gray200,
    .shadow;
    margin: 0 1.5em;
}

.tt-suggestion {
    @extend .py-2,
    .py-md-1,
    .px-3,
    .px-md-4,
    .text-gray300;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
        @extend .text-white,
        .bg-primary;
    }

    @include media-breakpoint-up(md) {
        font-size: 18px;
    }
}

.typeahead-form__icon {
    @extend .position-absolute,
    .top-50,
    .translate-middle-y,
    .fs-20,
    .text-primary;
    pointer-events: none;
    right: 15px;
}
/* PRODUCT GALLERY */

.product-gallery-holder {
    @extend .d-flex,
    .position-relative;
    min-width: 0;
    min-height: 0;
}

.product-gallery-box {
    @extend .flex-fill,
    .w-100,
    .pb-3,
    .pb-md-0;
    min-width: 0;
    min-height: 0;
}

.product-gallery {
    min-width: 0;

    .slick-dots {
        @extend .d-md-none;
        bottom: -12px;
    }
}

.product-gallery-thumbnails {
    @extend .flex-shrink-0,
    .me-3;
    width: 60px;

    .slick-slide {
        border-radius: 4px;
        border: 1px solid transparent;
        cursor: pointer;
        overflow: hidden;

        &.slick-current {
            border-color: $primary;
        }

        &+.slick-slide {
            @extend .mt-1;
        }
    }
}
/* Fonts */

//Roboto
@font-face {
    font-family: 'Roboto-Light';
    src: url('#{$font-path}roboto/Roboto-Light.woff2') format('woff2'),
    url('#{$font-path}roboto/Roboto-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Roboto-Bold';
    src: url('#{$font-path}roboto/Roboto-Bold.woff2') format('woff2'),
    url('#{$font-path}roboto/Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('#{$font-path}roboto/Roboto-Medium.woff2') format('woff2'),
    url('#{$font-path}roboto/Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Roboto-Regular';
    src: url('#{$font-path}roboto/Roboto-Regular.woff2') format('woff2'),
    url('#{$font-path}roboto/Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
/* VARIABLES */

/*************************
	Base colors
*************************/
$font-regular: 'Roboto-Regular';
$font-light: 'Roboto-Light';
$font-medium: 'Roboto-Medium';
$font-bold: 'Roboto-Bold';

.font-regular {
	font-family: $font-regular !important;
}

.font-light {
	font-family: $font-light !important;
}

.font-medium {
	font-family: $font-medium !important;
}

.font-bold {
	font-family: $font-bold !important;
}

/*************************
	Base variables
*************************/
$base-animation: 250ms;
/*************************
	Fonts variables
*************************/


.font-icons {
	&:before {
		font-family: 'icomoon';
	}
}


/*************************
	Font path variable
*************************/
$font-path: "../fonts/";

/*************************
	Body variables
*************************/
$body-font-size: 14px;
$body-line-height: 1.5;


/*************************
	Heading variables
*************************/
$heading-font: $font-bold;
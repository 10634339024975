/* GALLERY */

.slick-dots {
    @extend .list-unstyled,
    .position-absolute,
    .w-100,
    .start-50,
    .translate-middle-x,
    .end-0,
    .d-flex,
    .justify-content-center,
    .flex-wrap;

    >li {
        opacity: 1;
        border: 1px solid $white;
        @extend .bg-transparent,
        .rounded-circle,
        .mx-1;
        width: 9px;
        height: 9px;
        box-shadow: 0px 0px 2px 0px rgba($black, .7);
        cursor: pointer;

        &:hover,
        &:active,
        &.slick-active {
            @extend .bg-sky;
            border-color: $sky !important;
        }

        >button {
            @extend .d-none;
        }
    }
}

.slick-arrow {
    @extend .position-absolute,
    .font-icons,
    .text-silver200,
    .btn,
    .border-0,
    .shadow-none,
    .p-0;
    z-index: 30;
    cursor: pointer;
    font-size: 0;

    &:before {
        font-size: 20px;
    }



    &.slick-prev {
        @extend .start-0,
        .icon-chevron-left;
        left: 0 !important;
    }

    &.slick-next {
        @extend .end-0,
        .icon-chevron-right;
        right: 0px !important;
    }
}
/* FEEDBACK */

.feedback {
    border-top: 1px solid $silver;
    @extend .pt-1,
    .pb-4;
}

.feedback__header {
    @extend .d-flex,
    .flex-column,
    .flex-sm-row,
    .align-items-sm-center,
    .justify-content-sm-between,
    .mb-2;
}

.feedback__body {
    @include media-breakpoint-up(md) {
        font-size: 16px;
    }
}
/* SCROLL TO TOP */

$scroll-to-top-size: 38px;

.scroll-to-top {
    @extend .btn,
    .border-0,
    .p-0,
    .rounded-circle,
    .d-flex,
    .align-items-center,
    .justify-content-center,
    .position-fixed;
    width: $scroll-to-top-size;
    height: $scroll-to-top-size;
    background-color: rgba($white, .75);
    right: 1rem;
    bottom: 1rem;
    z-index: 600;
    font-size: 18px;
    @include transition($base-animation);
    box-shadow: 0px 0px 2px 1px rgba($black, .4) !important;

    transform: translateX(80px);

    &.is-visible {
        transform: translateX(0);
    }
}
/* MODAL */

.modal {}

.modal-dialog {
    @include media-breakpoint-up(md) {
        width: 600px;
    }
}

.modal-content {
    @extend .border-0;
    box-shadow: 0 5px 15px rgba($black, 50%);
    border-radius: 10px;
}

.modal-title {
    font-size: 34px;
}
/* FOOTER */

.footer {
    @extend .flex-shrink-0;

}

.footer__top {
    padding: 30px 0;

    @include media-breakpoint-up(md) {
        padding: 50px 0;
    }

    @include media-breakpoint-up(xxl) {
        padding: 65px 0;
    }
}

.footer__bottom {
    @extend .text-center,
    .fs-15,
    .bg-gray100;
    padding: 25px 0;

    @include media-breakpoint-up(md) {
        padding: 40px 0;
    }
}

.footer__logo {
    @extend .d-block;
    width: 140px;

    @include media-breakpoint-up(md) {
        width: 182px;
    }
}

.footer__nav {
    @extend .d-flex,
    .flex-column;
}

.footer-nav__item {
    @extend .text-gray400,
    .font-medium;

    @include media-breakpoint-up(md) {
        font-size: 18px;
    }

    &:hover,
    &:focus,
    &:active {
        @extend .text-primary;
    }

    &.footer-nav__item--heading {
        @extend .text-gray500;
        font-size: 16px;

        @include media-breakpoint-up(md) {
            font-size: 20px;
        }

        &:hover,
        &:focus,
        &:active {
            @extend .text-primary;
        }
    }

    &+.footer-nav__item {
        @extend .mt-3;
    }
}
/* Fonts icons */

//Icomoon
@font-face {
    font-family: 'icomoon';
    src: url('#{$font-path}icomoon/icomoon.eot?raxetc');
    src: url('#{$font-path}icomoon/icomoon.eot?raxetc#iefix') format('embedded-opentype'),
    url('#{$font-path}icomoon/icomoon.ttf?raxetc') format('truetype'),
    url('#{$font-path}icomoon/icomoon.woff?raxetc') format('woff'),
    url('#{$font-path}icomoon/icomoon.svg?raxetc#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-social-pinterest:before {
    content: "\e920";
}

.icon-social-tumbler:before {
    content: "\e921";
}

.icon-social-facebook:before {
    content: "\e922";
}

.icon-social-twitter:before {
    content: "\e923";
}

.icon-share1:before {
    content: "\e926";
}

.icon-share:before {
    content: "\e925";
}

.icon-mail:before {
    content: "\e924";
}

.icon-chevrons-up:before {
    content: "\e91a";
}

.icon-chevrons-right:before {
    content: "\e91b";
}

.icon-chevrons-left:before {
    content: "\e91c";
}

.icon-chevrons-down:before {
    content: "\e91d";
}

.icon-box:before {
    content: "\e919";
}

.icon-filter:before {
    content: "\e913";
}

.icon-free-shipping:before {
    content: "\e90e";
}

.icon-arrow-right-circle:before {
    content: "\e90f";
}

.icon-arrow-left-circle:before {
    content: "\e910";
}

.icon-arrow-down-circle:before {
    content: "\e911";
}

.icon-arrow-up-circle:before {
    content: "\e912";
}

.icon-chevron-right:before {
    content: "\e907";
}

.icon-chevron-left:before {
    content: "\e908";
}

.icon-chevron-down:before {
    content: "\e909";
}

.icon-chevron-up:before {
    content: "\e90a";
}

.icon-directions_car:before {
    content: "\e916";
}

.icon-star_outline:before {
    content: "\e90b";
}

.icon-star_half:before {
    content: "\e90c";
}

.icon-star:before {
    content: "\e90d";
}

.icon-live_help:before {
    content: "\e900";
}

.icon-circle-with-plus:before {
    content: "\e91f";
}

.icon-close:before {
    content: "\e905";
}

.icon-calendar:before {
    content: "\e91e";
}

.icon-notification:before {
    content: "\e917";
}

.icon-warning:before {
    content: "\e918";
}

.icon-minus:before {
    content: "\e915";
}

.icon-plus:before {
    content: "\e914";
}

.icon-add:before {
    content: "\e914";
}

.icon-sum:before {
    content: "\e914";
}

.icon-user:before {
    content: "\e906";
}

.icon-circle-left:before {
    content: "\e901";
}

.icon-left:before {
    content: "\e901";
}

.icon-circle-previous:before {
    content: "\e901";
}

.icon-arrow:before {
    content: "\e901";
}

.icon-circle-down:before {
    content: "\e902";
}

.icon-down:before {
    content: "\e902";
}

.icon-circle-bottom:before {
    content: "\e902";
}

.icon-arrow1:before {
    content: "\e902";
}

.icon-circle-right:before {
    content: "\e903";
}

.icon-right:before {
    content: "\e903";
}

.icon-circle-next:before {
    content: "\e903";
}

.icon-arrow2:before {
    content: "\e903";
}

.icon-circle-up:before {
    content: "\e904";
}

.icon-up:before {
    content: "\e904";
}

.icon-circle-top:before {
    content: "\e904";
}

.icon-arrow3:before {
    content: "\e904";
}

.icon-search:before {
    content: "\f002";
}

.icon-shopping-cart:before {
    content: "\f07a";
}

.icon-bars:before {
    content: "\f0c9";
}

.icon-navicon:before {
    content: "\f0c9";
}

.icon-reorder:before {
    content: "\f0c9";
}
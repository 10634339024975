/* LIST */

.list {
    @extend .d-flex,
    .flex-column;

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    &--lg {
        @include media-breakpoint-up(md) {
            font-size: 17px;
        }
    }
}

.list-item {
    @extend .position-relative,
    .py-2,
    .text-decoration-none,
    .text-black,
    .font-medium;
    padding-right: 30px;
    @extend .font-icons,
    .icon-chevrons-right;
    border-bottom: 1px solid transparent;

    &:hover,
    &:focus,
    &:active {
        border-bottom-color: $silver600;

        &:before {
            @extend .text-sky100;
            transform: translateX(5px);
        }
    }

    &:before {
        @extend .position-absolute,
        .text-silver600,
        .fs-14;
        right: 1rem;
        top: 9px;
        @include transition($base-animation);
    }

    &--active {
        border-color: $primary !important;
    }
}
/* SECTION */

.section {
    @extend .position-relative;
    padding: 20px 0;

    @include media-breakpoint-up(md) {
        padding: 50px 0;
    }

    &--sm {
        padding: 20px 0;
    }
}
/* BREADCRUMB */

.breadcrumb {
    @extend .fs-12;
}

.breadcrumb-item {
    @extend .text-gray700;
    flex-wrap: nowrap;
    white-space: nowrap;

    >a {
        @extend .text-gray700;

        &:hover,
        &:focus,
        &:active {
            @extend .text-primary;
        }
    }

    &+.breadcrumb-item {
        &:before {
            content: '>';
        }
    }

    &.active {
        @extend .font-bold;
        white-space: normal;
        flex-wrap: wrap;
    }
}
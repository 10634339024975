/* QTY */

.qty {
    @extend .d-inline-flex;
    border: 1px solid $gray800;
}

.qty-input {
    @extend .form-control,
    .border-0,
    .shadow-none,
    .flex-fill,
    .font-light,
    .text-center,
    .text-gray;
    font-size: 30px;
    width: 54px;
    height: 54px;
}

.qty-controls {
    @extend .flex-shrink-0,
    .d-flex,
    .flex-column;
    width: 38px;
    border-left: 1px solid $gray800;
}

.qty-control {
    @extend .d-flex,
    .align-items-center,
    .justify-content-center,
    .flex-fill,
    .font-bold,
    .text-gray,
    .fs-13;

    &+.qty-control {
        border-top: 1px solid $gray800;
    }
}
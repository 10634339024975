/* RELATEDD GALLERY */

.featured-news-gallery {
    &:not(:last-child) {
        margin-bottom: 20px;
    }

    .slick-arrow {
        @extend .top-50,
        .translate-middle-y;

        &:before {
            font-size: 30px;
        }

        &.slick-prev {
            left: 0.5rem;
        }

        &.slick-next {
            right: 0.5rem;
        }
    }

    .slick-dots {
        bottom: 10px;
    }
}

.featured-news-gallery-button {
    @extend .position-absolute,
    .top-50,
    .translate-middle-y,
    .font-icons,
    .text-silver200;
    z-index: 30;
    font-size: 40px;
    cursor: pointer;

    @include media-breakpoint-up(md) {
        font-size: 50px;
    }

    &--prev {
        @extend .start-0,
        .icon-chevron-left;

        @include media-breakpoint-up(lg) {
            left: -10px !important;
        }
    }

    &--next {
        @extend .end-0,
        .icon-chevron-right;

        @include media-breakpoint-up(lg) {
            right: -10px !important;
        }
    }
}
/* RESET STYLES */

* {

  &:hover,
  &:focus,
  &:active {
    outline: none !important;
  }
}

a:hover,
a:focus,
a:visited {
  outline: none;
  outline-offset: 0;
}

ol,
ul {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}
/* NAVIGATION */

$navigation-toggle-size: 32px;

.navigation {
    @extend .position-fixed,
    .start-0,
    .top-0,
    .overflow-auto,
    .bg-white,
    .h-100;
    width: 80vw;
    z-index: 600;
    @include transition($base-animation);
    transform: translateX(-100%);

    @include media-breakpoint-up(md) {
        width: 440px;
    }

    &.is-active {
        transform: translateX(0%);
    }
}

.navigation-mask {
    @extend .position-fixed,
    .start-0,
    .top-0,
    .h-100,
    .w-100;
    background-color: rgba($black, .7);
    cursor: pointer;
    z-index: 580;
    @include transition($base-animation);
    opacity: 0;
    visibility: hidden;

    &.is-active {
        opacity: 1;
        visibility: visible;
    }
}

.navigation-toggle {
    @extend .btn,
    .shadow-none,
    .bg-white,
    .rounded-0,
    .d-flex,
    .flex-column,
    .align-items-center,
    .justify-content-center,
    .text-gray700,
    .p-0;
    font-size: 25.5px;
    z-index: 540;
    width: $navigation-toggle-size;
    height: $navigation-toggle-size;

    @include media-breakpoint-up(md) {
        font-size: 28px;
        width: $navigation-toggle-size + 8px;
        height: $navigation-toggle-size + 8px;
    }
}

.navigation__header,
.navigation__body {
    @extend .p-3;
}

.navigation__header {
    @extend .d-flex,
    .align-items-center;
    border-bottom: 1px solid $silver;
}

.navigation-divider {
    @extend .my-3,
    .bg-silver;
    height: 1px;
}
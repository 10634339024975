/* PRODUCTS NAV */

.products-nav {
    @include media-breakpoint-down(lg) {
        position: fixed;
        width: 80vw;
        z-index: 640;
        height: 100%;
        background-color: $white;
        @include transition($base-animation);
        top: 0;
        left: 0;
        padding: 1rem;
        overflow-y: auto;
        transform: translateX(-100%);
    }

    &.is-active {
        transform: translateX(0%);
    }
}

.products-nav-toggle {
    @extend .btn,
    .btn-sky,
    .mb-4,
    .mb-lg-0;
}

.products-nav-mask {
    @extend .navigation-mask,
    .d-lg-none;
}
/* BLOG POST */

.blog-post {
    &+.blog-post {
        margin-top: 30px;

        @include media-breakpoint-up(md) {
            margin-top: 50px;
        }
    }

    &:last-child {
        @extend .mb-4;
    }
}

.blog-post__title {
    @extend .font-medium;
    font-size: 24px;

    @include media-breakpoint-up(md) {
        font-size: 28px;
    }
}

.blog-post__list {
    padding-left: 17px;

    @include media-breakpoint-up(md) {
        font-size: 17px;
    }

    >li {
        @extend .py-1;

        >a {
            color: $gray400;

            &:hover,
            &:focus,
            &:active {
                color: $gray800;
            }
        }
    }



}
html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  font-size: $body-font-size;
  line-height: $body-line-height;

  @include media-breakpoint-up(md) {
    height: 100%;
  }
}

body {
  @extend .d-md-flex,
  .flex-md-column;
}

.main {
  flex: 1 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 1.35;
  font-family: $heading-font;

  &:last-child {
    margin-bottom: 0;
  }
}

h1,
.h1 {
  font-size: 24px;
  line-height: 1.15;

  @include media-breakpoint-up(md) {
    font-size: 40px;
  }
}

h2,
.h2 {
  font-size: 16px;
  line-height: 1.15;

  @include media-breakpoint-up(md) {
    font-size: 36px;
  }
}

.h2-desktop {
  font-size: 28px;

  @include media-breakpoint-up(md) {
    font-size: 36px;
  }
}

h3,
.h3 {
  font-size: 14px;

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
}

h4,
.h4 {
  font-size: 14px;

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
}

h5,
.h5 {
  font-size: 14px;

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
}

h6,
.h6 {}

.aside-title {
  font-size: 18px;

  @include media-breakpoint-up(md) {
    font-size: 22px;
  }
}

button,
a {
  @include transition($base-animation);
  cursor: pointer;
}

a {
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

p {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

b,
.bold {
  font-family: $heading-font;
}

hr {
  border-top-color: $silver;
}
/* PRODUCT */

.product {
    @extend .position-relative,
    .px-3,
    .pb-3,
    .pt-2,
    .bg-white,
    .text-reset,
    .d-flex,
    .flex-column,
    .text-center,
    .text-decoration-none;
    align-items: center;
    // min-height: 100%;

    &:hover,
    &:focus,
    &:active {
        @extend .shadow;
    }

    @include media-breakpoint-down(sm) {
        font-size: 13px;
    }
}

.product__img {
    @extend .d-block,
    .w-100;
    max-width: 110px;

    .products-list & {
        max-width: 100%;
        height: auto !important;
        @include media-breakpoint-up(md) {
            padding: 0.5em;
        }
    }
}

.product-heading {
    font-size: 23px;
}

.product-title {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 19px;
    /* fallback */
    max-height: 76px;
    /* fallback */
    -webkit-line-clamp: 4;
    /* number of lines to show */
}
/* PRODUCTS FILTER */

.products-filter {
    @extend .d-flex,
    .align-items-center,
    .justify-content-end;

    @include media-breakpoint-down(sm) {
        justify-content: start !important;
    }
}

.products-filter-pagination,
.products-filter-sort {
    @extend .d-flex,
    .align-items-center;
}

.products-filter-sort {
    .form-select {
        max-width: 120px;
    }
}
/* TAGS */

.tags {
    @extend .d-flex,
    .flex-wrap;

    &--popular {
        .tag {
            &:not(:last-child) {
                &:after {
                    content: none;
                }
            }
        }

        .tag-link {
            @extend .text-white,
            .bg-sky200,
            .me-2;
            border: 1px solid $sky200;
            padding: 3px 8px;

            &:hover,
            &:focus,
            &:active {
                @extend .text-sky200,
                .bg-transparent;
            }
        }
    }
}

.tag {
    &:not(:last-child) {
        &:after {
            content: ',';
            @extend .d-inline-flex,
            .mx-1;
        }
    }
}

.tag-link {
    @extend .text-sky100;
    border-radius: 4px;
}
/* PRE FOOTER FORM */

.pre-footer-form {
    @extend .d-flex;

    .form-control {
        @extend .border-0,
        .flex-fill,
        .px-3,
        .px-md-4,
        .text-primary;
        height: 47px;
        border-radius: 23px 0 0 23px;
        @include custom-placeholder($color: $primary,
            $font-size: inherit,
            $font-family: $font-regular);

        @include media-breakpoint-up(md) {
            font-size: 18px;
            @include custom-placeholder($color: $primary,
                $font-size: 18px,
                $font-family: $font-regular);
        }
    }

    .btn-submit {
        @extend .flex-shrink-0,
        .px-3;
        border-radius: 0 23px 23px 0;

        @include media-breakpoint-up(md) {
            font-size: 18px;
        }
    }
}
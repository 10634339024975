/* SOCIALS */

$social-color: #cdcdcd;

.socials {
    @extend .d-flex,
    .flex-wrap;
}

.social {
    @extend .d-inline-flex,
    .align-items-center,
    .justify-content-center,
    .rounded-circle,
    .me-1,
    .mb-1,
    .text-decoration-none,
    .fs-20,
    .text-center;
    width: 40px;
    height: 40px;
    border: 1px solid $social-color;
    color: $social-color;

    &:hover,
    &:focus,
    &:active {
        @extend .text-primary;
        border-color: $primary;
    }
}
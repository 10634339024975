/* BUTTONS */

.btn {
    font-family: $font-bold;
    border-radius: 4px;
    padding: 7px 9px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }

    &-sky {
        @include button-variants($bg: $sky, $color: $white);
    }

    &-success {
        @include button-variants($bg: $success, $color: $white);
    }

    &-success100 {
        @include button-variants($bg: $success100, $color: $primary);
    }

    &-success400 {
        @include button-variants($bg: $success400, $color: $white);
    }

    &-outline-warning {
        @include button-outline-variants($color: $yellow100);
    }

    &-outline-secondary {
        @include button-outline-variants($color: $silver200);
    }

    &-block {
        display: flex;
        width: 100%;
    }

    &-block-md {
        @include media-breakpoint-up(md) {
            display: flex;
            width: 100%;
        }
    }

    &-lg {
        font-size: 18px;
        padding: 11.5px 30px;
    }


}

.btn-icon {
    @extend .d-inline-flex,
    .align-items-center,
    .justify-content-center,
    .rounded-circle,
    .flex-shrink-0;
    width: 34px;
    height: 34px;
    @include transition($base-animation);
}

.btn-product-info {
    font-size: 12px;

    @include media-breakpoint-up(md) {
        font-size: 16px;
    }
}